<template>
  <div>
    <div class=" row Aff-TestAnalysisUserReport-Header">
      <div class="col s12 m10">
        <strong style="color: Black">Effort Analysis</strong>
        <strong><a style="color: Black" @click="Goback()"> / Test Analysis</a></strong>
        <strong style="color: #642c90"> / {{this.AffiliationUserName}}</strong>
      </div>
      <div class="col s12 m2 right-align" v-if="this.Source === 1"><a class="Affbackbutton waves-effect waves-light btn getclassbutton" @click="Goback()"><span class="Tiny material-icons"
     style="position: relative;top: 7px;margin-right: 2px;">arrow_back</span>Go Back</a></div>
    </div>
    <div class="divider div2 hide-on-small-only" style="width: 100%"></div>
    <!-- <div class="card card-panel" v-if="this.Source === 2">
        <div class="row">
        <div class="col l3 m6 s12  tadtitle">
          <strong>Date</strong>
          <select class="browser-default tadropd" v-model="dateRangeType" @change="showCalendar()">
            <option value="Date" disabled selected>-- Select --</option>
            <option v-for="(period, index) in Timeinterval" :key="index" :value="index +1">
              {{ period }}
            </option>
          </select>
        </div>
         <div class="row" v-if="isDateRangeOpen">
        <div class="col l3 m6 s12 tadtitle">
          <strong>Start Date</strong>
          <input type="date" max="2999-12-31" min="1900-01-01" name="start date" class="browser-default tadt" style="display: block"
            v-model="startDate" @change="showCalendar()" />
          <span v-if="isStartDateSelected"
              class="red-text" style="font-size:13px;">{{validationMessageOfDates}}</span>
        </div>
        <div class="col l3 m6 s12 tadtitle">
          <strong>End Date</strong>
          <input type="date" max="2999-12-31" min="1900-01-01" name="end Date" class="browser-default tadt" style="display: block"
            v-model="endDate" @change="showCalendar()" />
          <span v-if="isEndDateSelected"
              class="red-text" style="font-size:13px;">{{validationMessageOfDatesEndDate}}</span>
        </div>
      </div>
       <div class="col l3 m6 s12" v-if="SearchShow">
          <a class="waves-effect waves-light btn taursubmitbutton"
            @click="getPracticeAnalysisUserReport()">Search</a>
        </div>
    </div>
    </div> -->
    <table class="qtl striped" style="width: 100%">
      <thead>
        <tr>
          <!-- <td class="center">Date</td> -->
          <th class="left-align">Name</th>
          <th class="center">Date</th>
          <th class="center-align">Subject</th>
          <th class="center">Total Marks</th>
          <th class="center">Total Questions</th>
          <th class="center">Attempt%</th>
          <th class="center">Accuracy%</th>
          <th class="center"><span class="material-icons"
                  style="position: relative;top: 5px;margin-right: 2px;">alarm</span> Duration</th>
          <th class="center"></th>
          <th></th>
        </tr>
      </thead>
      <tbody class="card-panel">
        <tr v-for="(data, index) in studData" :key="index">
          <td class="left-align">{{data.Title}}</td>
          <td class="center">{{formatDate(data.StartDate)}}<br>{{formatTime(data.StartDate)}}
          </td>
          <td class="center">{{data.SubjectName}}</td>
          <td class="center">{{data.TotalMarks}}</td>
          <td class="center">{{data.TotalQuestions}}</td>
          <td class="center">{{data.AttemptPercentage}}</td>
          <td class="center">{{data.Accuracy}}</td>
          <td class="center">
            <div v-if="data">
              <span v-if="data.Duration >= 3600">{{
                        Math.floor(
                          data.Duration/ 3600
                        ) + " hrs"
                      }}</span>
              <span v-else-if="data.Duration >= 60">{{
                        Math.floor(
                          data.Duration / 60
                        ) + " hr"
                      }}</span>
              <span v-else>{{
                      data.Duration + " min"
                    }}</span>
            </div>
          </td>
          <td class="left-align"><a @click="testReport(data.InstituteTestUserId)">View</a></td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// import Vue from "vue";
import moment from "moment";
import MobileAPI from "../Mobileapi";
/*eslint-disable*/
  // Vue.filter("formatDate", (value) => {
  //   if (value) {
  //     return moment(String(value)).utcOffset("-00:00").format("DD/MM/YYYY");
  //   }
  // });
  // Vue.filter("formatTime", (value) => {
  //   if (value) {
  //     return moment(String(value)).utcOffset("-00:00").format("hh:mm a");
  //   }
  // });
  export default {
    data() {
      return {
        UserId: null,
        AffiliationUserName: "",
        studData: [],
        SubjcetList: [],
        getdata: [],
        UserTestData: null,
	    	AffiliationTestTitle: "",
        InstituteTestUserID: null,
        Source: null,
        isDateRangeOpen: false,
        Timeinterval:
        [
          "Today",
          "Yesterday",
          "Last Week",
          "Custom",
        ],
        SearchShow: false,
      };
    },
    activated() {
		this.reset();
        this.Source = Number(this.$route.params.Source);
      // console.log("ConductTest activated");
      if(this.$route.params.UserId){

      this.UserId = parseInt(this.$route.params.UserId);
      this.AffiliationUserName = this.$route.params.name;
      this.instituteTestId = this.$route.params.testid
      this.UserTestData = JSON.parse(this.$route.params.FisrtPageData);
	    this.AffiliationTestTitle = this.$route.params.TestName;
      }else{
        this.UserId = Number(this.$route.params.StuAffDashUserId);
        this.AffiliationUserName = this.$route.params.StuAffDashUserName;
      }
      this.$store.dispatch("showLoader", true);
      MobileAPI.AffiliationTestAnalysisUserReport(this.UserId, (response) => {
        // console.log(response);
        this.studData = response.data[0];
        this.$store.dispatch("showLoader", false);
      });
    },
    methods: {
      showCalendar() {
      this.SearchShow = true;
      if (this.dateRangeType === 4) {
        this.isDateRangeOpen = true;
        this.isStartDateSelected = false;
        this.isEndDateSelected = false;
      } else {
        this.isDateRangeOpen = false;
      }
    },
    formatDate(value) {
if (value) {
      return moment(String(value)).utcOffset("-00:00").format("DD/MM/YYYY");
    }
},
formatTime (value){
if (value) {
      return moment(String(value)).utcOffset("-00:00").format("hh:mm a");
    }
},
      reset() {
        this.studData = [];
      },
	  Goback(){
		   this.$router.push({
          name: "TestAnalysisReport",
            params: {
          	  instituteTestId: this.instituteTestId,
              testname: JSON.stringify(this.UserTestData),
              title: this.AffiliationTestTitle,
              pageName: "TestAnalysis",
            }
        });
        // this.studData = [];
	  },
      testReport(instituteTestUserId) {
        this.InstituteTestUserID = instituteTestUserId
        this.$router.push({
          name: "TestReport",
          params: {
            testUserId: Number(this.InstituteTestUserID),
            testType: 2,
            UserId: this.UserId,
            name: this.AffiliationUserName,
            previouspage: true,
		      	testid: this.instituteTestId,
		      	testname: this.UserTestData,
		      	TestName: this.AffiliationTestTitle,
          },
          query: {
            goToTestAnalysisUserReport : true,
          }
        });
      }
    },

  };

</script>

<style scoped>
  .Aff-TestAnalysisUserReport-Header{
    margin-top: 19px;
    font-size: 25px;
    color: #642c90;
  }

  .div2 {
    margin-top: 18.5px;
    height: 0px;
    left: 132px;
    border: 1px solid #e0e0e0;
  }

  table.qtl {
    border-collapse: separate;
    border-spacing: 0 10px;
  }

  table.qtl thead tr {
    background-color: #6a2f85;
    color: #fff;
  }

  table.qtl thead tr td:first-child {
    border-radius: 5px 0 0 5px;
  }

  table.qtl thead tr td {
    border-radius: 0;
  }
  .Affbackbutton {
    width: 144px;
	font-size: 12px;
    background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
  }
 .taursubmitbutton {
    background: linear-gradient(90.2deg, #FF9421 0.16%, #FBAD26 98.41%);
    border-radius: 5px;
    font-size: 14px;
    margin-left: 10px;
    margin-top: 33px;
    width: 130px;
    height: 38px;
  }
   .tadtitle {
    font-size: 18px;
    color: #333333;
  }
  .tadropd {
    width: 268px;
    height: 36px;
    margin-top: 8px;
    border-radius: 5px;
  }
  .tadt {
    width: 260px;
    height: 36px;
    border-radius: 5px;
    border: 1.5px solid #E3E3E3;
    margin-top: 8px;
  }
</style>
